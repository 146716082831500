import { IUser } from "@solarforschools/sfs-core";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getUsersApi = (query: any): Promise<any> => {
  return axios
    .get(
      `${host}/users/new?${generateQueryString(query as ParsedUrlQueryInput)}`
    )
    .then((res) => res.data);
};
export const createUserAPi = (data: IUser): Promise<any> => {
  return axios.post(`${host}/users`, data).then((res) => res.data);
};

export const updateUserApi = (data: IUser): Promise<any> => {
  return axios.put(`${host}/users/${data._id}`, data).then((res) => res.data);
};

export const deleteUserApi = (data: IUser): Promise<any> => {
  return axios.delete(`${host}/users/${data._id}`).then((res) => res.data);
};

export const validateUserApi = (user: IUser): Promise<any> => {
  return axios.put(`${host}/users/validate/user`, user).then((res) => res.data);
};

export const createActivity = (data: any): Promise<any> => {
  return axios.post(`${host}/activity`, data);
};

export const getUserJWTToken = (userId: string): Promise<any> => {
  return axios
    .get(
      `${host}/users/${userId}/auth-token`
    )
    .then((res) => res.data);
};
