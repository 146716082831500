import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  IAuthEvent,
  ILoginCredentials,
  IWebUser,
  LogoutUser,
  SetAuthToken,
  SetFetching,
  SetAuthEvent,
  SetUserDetails,
  SetRedirectURL,
} from "./actionTypes";
import setAuthTokenInAxios from "../../utils/setAuthToken";
import axios from "axios";
import { host } from "../config";
import { toast } from "react-toastify";
import jwt from "jsonwebtoken";
import store from "../index";

// Action Creators
export const isLoading = (isLoading: boolean): SetFetching => {
  return { type: "SET_FETCHING", isLoading };
};

const decodeJwt = (token: string): IWebUser => {
  const decode = jwt.decode(token) as IWebUser;
  return decode;
};

export const setUserDetails = (): SetUserDetails => {
  const token = localStorage.getItem("token") as string;
  let user;
  try {
    user = decodeJwt(token);
    let isValid = false;
    const now = Math.floor(Date.now() / 1000);
    if (now < user.exp) {
      isValid = true;
    }
    return {
      type: "SET_USER_DETAILS",
      webUser: user,
      isValid,
    };
  } catch (e) {
    if (token) toast.warn("Invalid Token.");
  }
  return {
    type: "SET_USER_DETAILS",
    isValid: false,
  };
};

export const setAuthToken = (token: string): SetAuthToken => {
  localStorage.setItem("token", token);
  localStorage.setItem("isTokenValid", "true");
  setAuthTokenInAxios(token);
  return { type: "SET_AUTH_TOKEN", token };
};

export const setAuthEvent = (authEvent: IAuthEvent): SetAuthEvent => {
  return { type: "REGISTER_AUTH_EVENT", authEvent };
};

export const setRedirectURL = (to: string): SetRedirectURL => {
  return { type: "Redirect", to };
};

export const logout = (): LogoutUser => {
  localStorage.removeItem("token");
  localStorage.removeItem("isTokenValid");
  return { type: "LOGOUT" };
};

export const login = (
  credentials: ILoginCredentials
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(isLoading(true));
        axios
          .post(`${host}/login`, credentials)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Login Success !");
              dispatch(isLoading(false));
              dispatch(setAuthToken(res.data.access_token));
              dispatch(setUserDetails());
              const redirectURL = store.getState().web.admin.redirectTo;
              if (redirectURL) {
                dispatch(setRedirectURL(""));
                window.location.replace(redirectURL);
              }
            }
          })
          .catch((err) => {
            if (err?.response?.status === 401) {
              toast.error(err.response.data.msg);
            } else if (err?.response?.status === 400) {
              toast.error(err.response.data.msg);
            }
            setTimeout(() => {
              dispatch(isLoading(false));
            }, 500);
          });
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const registerAuthEvent = (
  authEvent: IAuthEvent
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(setAuthEvent(authEvent));
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const logoutUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(logout());
        dispatch(isLoading(false));
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const verifyUserStatus = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(setUserDetails());
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const uploadLogFile = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getSate
  ): Promise<void> => {
    dispatch(isLoading(true));
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.get(`${host}/report/logs`);

        dispatch(isLoading(false));
        if (res.status === 200) {
          toast.success(res.data.msg, { autoClose: false, closeOnClick: true });
        }
        resolve();
      } catch (e) {
        dispatch(isLoading(false));
        resolve();
      }
    });
  };
};
