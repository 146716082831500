import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  IChoiceGroupOption,
  IComboBoxOption,
  IComboBoxStyles,
  IStackTokens,
  Label,
  Stack,
  TextField
} from "@fluentui/react/lib/index";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { isLoading } from "../../../store/admin/action";
import {
  fetchProjects, fetchSchools, fetchSchoolsCountries, fetchSchoolsFilters, fetchUsers,
  setSchoolsFilter
} from "../../../store/admin/school/action";
import { ISchool } from "../../../store/admin/school/reducer";
import { countries, Regions } from "../common/common";
import ExpandableFilters from "../common/ExpandableFilter";
import Loader from "../common/Loader";
import { stageStatuses } from "../task/common";
import { fileldsFilter, stages } from "./common";
import SchoolList from "./SchoolList";


const Index = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<boolean>(false)
  const schools: ISchool = useSelector<RootState, ISchool>(
    (state) => state.web.school
  );
  const comboBoxStyles: Partial<IComboBoxStyles> = {
    container: { width: 250 },
  };
  const stackTokens: IStackTokens = { childrenGap: 10 };
  const textFieldStyle = {};
  const statusOptions: IChoiceGroupOption[] = [
    { key: "all", text: "All" },
    { key: "live", text: "Live" },
    { key: "Open", text: "Open" },
    { key: "Closed", text: "Closed" },
    { key: "Deleted", text: "Deleted" },
  ];
  useEffect(() => {
    (async () => {
      await dispatch(isLoading(true));
      await dispatch(fetchSchools(undefined, false));
      await dispatch(isLoading(false));
    })();
    dispatch(fetchUsers({ all: true }, false));
    dispatch(fetchProjects({ all: true }, false));
    dispatch(fetchSchoolsCountries(undefined, false))
    dispatch(fetchSchoolsFilters(undefined, false))
    setMoreOpen()
  }, []);

  const handleOnChange = (item?: any, key?: string, type?: boolean) => {
    const filterParams = { ...schools.schoolsFilter };
    let filters = {};
    switch (key) {
      case "filterBy":
        let filterBy = [...filterParams.filterBy!];
        if (!filterBy?.find((s) => s === item)) {
          filterBy.push(item);
        } else {
          filterBy = filterBy?.filter((s) => s !== item) as string[];
        }
        filters = { filterBy };
        break;
      case "stages":
        let stage = filterParams.stage || [];
        if (item?.selected) {
          if (!stage?.find((s) => s === item?.key)) {
            stage.push(item.key);
          }
        } else {
          stage = stage?.filter((s) => s !== item?.key) as string[];
        }
        filters = { stage };
        break;
      default:
        filters = { [key as string]: item };
        break;
    }
    const params = { ...schools.schoolsFilter, page: 1, ...filters };

    dispatch(setSchoolsFilter(params));
    if (type) {
      if (item.length > 2 || item.length === 0) {
        dispatch(fetchSchools());
      }
    } else if (key === "field1") {
      if (params.value1?.length! > 2) dispatch(fetchSchools());
    } else if (key === "field2") {
      if (params.value2?.length! > 2) dispatch(fetchSchools());
    } else {
      dispatch(fetchSchools());
    }
    setMoreOpen()
  };

  const setMoreOpen = () => {
    const {
      zipcode,
      city,
      state,
      district,
      field1 = "all",
      field2 = "all",
      user,
      project,
      filterBy,
    } = schools?.schoolsFilter || {};
    if (zipcode || city || state || user !== 'all' || field1 !== "all" || project !== 'all' || filterBy?.length || district || field2 !== "all") setExpanded(true)
  }

  const {
    name,
    zipcode,
    city,
    state,
    district,
    region = "all",
    country = 'all',
    field1 = "all",
    value1,
    field2 = "all",
    value2,
    stage,
    user,
    project,
    status = "all",
    filterBy,
    stageStatus
  } = schools?.schoolsFilter || {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Schools</h1>

        <div
          className="ms-Grid ms-Grid-row"
          dir="ltr"
          style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
        >
          <TextField
            label="School Name"
            placeholder="Enter School Name"
            type="text"
            onChange={(e, value) => handleOnChange(value, "name", true)}
            value={name!}
            className="ms-Grid-col ms-lg3"
          />
          <ComboBox
            label="Stage"
            selectedKey={stage! || null}
            placeholder="Select"
            multiSelect
            allowFreeform={true}
            autoComplete={"on"}
            options={
              stages.filter((s) => s.key !== "all") as IComboBoxOption[]
            }
            onChange={(e, item) => handleOnChange(item, "stages")}
            styles={comboBoxStyles}
            className="ms-Grid-col ms-lg2"
          />
          <Dropdown
            label="Stage Active"
            selectedKey={stageStatus ? stageStatus : "all"}
            options={stageStatuses}
            onChange={(e, item) => handleOnChange(item?.key, "stageStatus")}
            className="ms-Grid-col ms-lg1"
          />
          <Dropdown
            label="Region"
            selectedKey={region ? region : "all"}
            options={Regions}
            onChange={(e, item) => handleOnChange(item?.key, "region")}
            className="ms-Grid-col ms-lg2"
          />
          <Dropdown
            label="Country (address)"
            selectedKey={country! || 'all'}
            placeholder="Select"
            options={
              schools.countries as IComboBoxOption[]
            }
            onChange={(e, item) => handleOnChange(item?.key, "country")}
            styles={comboBoxStyles}
            className="ms-Grid-col ms-lg2"
          />
          <Dropdown
            label="Status"
            selectedKey={status || "all"}
            options={statusOptions as IComboBoxOption[]}
            onChange={(e, item) => handleOnChange(item?.key, "status")}
            className="ms-Grid-col ms-lg1"
          />
          <ExpandableFilters expanded={expanded}>
            <TextField
              style={textFieldStyle}
              label="City"
              placeholder="Enter City"
              type="text"
              onChange={(e, value) => handleOnChange(value, "city", true)}
              value={city!}
              className="ms-Grid-col ms-lg2"
            />
            <TextField
              style={textFieldStyle}
              label="Zip Code"
              placeholder="Enter Zipcode"
              type="text"
              onChange={(e, value) => handleOnChange(value, "zipcode", true)}
              value={zipcode!}
              className="ms-Grid-col ms-lg2"
            />
            <TextField
              style={textFieldStyle}
              label="State"
              placeholder="Enter State"
              type="text"
              onChange={(e, value) => handleOnChange(value, "state", true)}
              value={state!}
              className="ms-Grid-col ms-lg2"
            />
            <TextField
              style={textFieldStyle}
              label="District"
              placeholder="Enter District"
              type="text"
              onChange={(e, value) => handleOnChange(value, "district", true)}
              value={district!}
              className="ms-Grid-col ms-lg2"
            />
            <Dropdown
              label="Assigned to"
              selectedKey={user ? user : "all"}
              options={schools?.users as IComboBoxOption[]}
              onChange={(e, item) => handleOnChange(item?.key, "user")}
              className="ms-Grid-col ms-lg3"
            />
            <Dropdown
              label="Project"
              selectedKey={project ? project : "all"}
              options={schools?.projects as IComboBoxOption[]}
              onChange={(e, item) => handleOnChange(item?.key, "project")}
              className="ms-Grid-col ms-lg3"
            />
            <Dropdown
              label="Field 1"
              selectedKey={field1 ? field1 : "all"}
              options={fileldsFilter}
              onChange={(e, item) => handleOnChange(item?.key, "field1")}
              className="ms-Grid-col ms-lg2"
            />

            {field1 && field1 !== "all" && (
              <TextField
                style={textFieldStyle}
                label="Value 1"
                placeholder="Enter Value1"
                type="text"
                onChange={(e, value) => handleOnChange(value, "value1", true)}
                value={value1!}
                className="ms-Grid-col ms-lg2"
              />
            )}
            <Dropdown
              label="Field 2"
              selectedKey={field2 ? field2 : "all"}
              options={fileldsFilter}
              onChange={(e, item) => handleOnChange(item?.key, "field2")}
              className="ms-Grid-col ms-lg2"
            />

            {field2 && field2 !== "all" && (
              <TextField
                style={textFieldStyle}
                label="Value 2"
                placeholder="Enter Value2"
                type="text"
                onChange={(e, value) => handleOnChange(value, "value2", true)}
                value={value2!}
                className="ms-Grid-col ms-lg2"
              />
            )}
            <div className="ms-Grid-col ms-lg5">
              <Stack horizontal horizontalAlign="start" tokens={stackTokens}>
                <Stack.Item className="margin-top-sm margin-bottom-sm">
                  <span style={{ fontSize: "bold" }}>Filter By</span>
                </Stack.Item>
              </Stack>
              <Stack horizontal horizontalAlign="start" tokens={stackTokens}>
                <Stack.Item>
                  <Checkbox
                    label="Authority"
                    checked={
                      filterBy?.find(
                        (a) => a === "authority"
                      ) as unknown as boolean
                    }
                    onChange={(e, value) =>
                      handleOnChange("authority", "filterBy")
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <Checkbox
                    label="Community"
                    checked={
                      filterBy?.find(
                        (a) => a === "community"
                      ) as unknown as boolean
                    }
                    onChange={(e, value) =>
                      handleOnChange("community", "filterBy")
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <Checkbox
                    label="Dioceses"
                    checked={
                      filterBy?.find(
                        (a) => a === "diocese"
                      ) as unknown as boolean
                    }
                    onChange={(e, value) =>
                      handleOnChange("diocese", "filterBy")
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <Checkbox
                    label="Trust"
                    checked={
                      filterBy?.find(
                        (a) => a === "trust"
                      ) as unknown as boolean
                    }
                    onChange={(e, value) =>
                      handleOnChange("trust", "filterBy")
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <Checkbox
                    label="Premium"
                    checked={
                      filterBy?.find(
                        (a) => a === "premium"
                      ) as unknown as boolean
                    }
                    onChange={(e, value) =>
                      handleOnChange("premium", "filterBy")
                    }
                  />
                </Stack.Item>
              </Stack>
            </div>
          </ExpandableFilters>
        </div>
      </header>
      <SchoolList actions={['add', 'edit', 'delete', 'stage', 'opportunity']} />
    </div>
  );
};

export default Index;
