import MomentTz from "moment-timezone";
import { Meta } from "@solarforschools/sfs-core/dist/school/types";

export const TimeZones = () => {
  const timeZones = MomentTz.tz.names();
  const offsetTmz = [];
  for (const tz of timeZones) {
    offsetTmz.push({ key: tz, text: tz });
  }
  return offsetTmz;
};

export const BelongsTo = [
  { key: "auhtority", text: "Authority" },
  { key: "community", text: "Community" },
  { key: "diocese", text: "Dioceses" },
  { key: "trust", text: "Trust" },
  { key: "premium", text: "Premium" },
];

export const BooleanValues = [
  { key: "false", text: "false" },
  { key: "true", text: "true" },
];

export const stages = [
  { key: "all", text: "Select" },
  { key: "Prospect", text: "Prospect" },
  { key: "Sales", text: "Sales" },
  { key: "Develop", text: "Develop" },
  { key: "Build", text: "Build" },
  { key: "Operate", text: "Operate" },
  { key: "Funded", text: "Funded" },
  { key: "Managed", text: "Managed" },
  { key: "Developed", text: "Developed" },
  { key: "Closed", text: "Closed" },
];

export const metaStatusOptions = [
  { key: "Open", text: "Open" },
  { key: "Closed", text: "Closed" },
  { key: "Deleted", text: "Deleted" },
];

export const addressComponent = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  postal_town: "short_name",
  country: "long_name",
  postal_code: "short_name",
  administrative_area_level_1: "long_name",
  administrative_area_level_2: "short_name",
  neighborhood: "short_name",
};

export const fileldsFilter = [
  { key: "all", text: "Select" },
  { key: "meta.authority", text: "Authority" },
  { key: "meta.community", text: "Community" },
  { key: "meta.diocese", text: "Diocese" },
  { key: "meta.group", text: "Group" },
  { key: "meta.type", text: "Type" },
  { key: "meta.trust", text: "Trust" },
  { key: "meta.phaseOfEducation", text: "Phase Of Education" },
  { key: "meta.religiousCharacter", text: "Religious Character" },
  { key: "meta.schoolSponsors", text: "School Sponsors" },
  { key: "address.search", text: "Address Search" },
  { key: "address.county", text: "Address county" },
  { key: "address.street", text: "Address Street" },
  { key: "address.formatted", text: "Address Formatted" },
  { key: "address.place_id", text: "Address Place Id" },
  { key: "address.global_code", text: "Address Global Code" },
];

export const mapLibs = ["geometry", "places"];
