import { Checkbox, ComboBox, DatePicker, Dropdown, IComboBoxOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isLoading } from '../../../store/admin/action';
import { getMeterReadings, setMeterReadingFilter } from '../../../store/admin/meterReading/action';
import { IMeterReadingState } from '../../../store/admin/meterReading/reducer';
import { comboBoxStyles, dropdownStyles, ReadingTypes } from '../common/common';
import { MeterTypes, Providers } from '../system/common';
import SystemPicker from '../common/SystemPicker';
import SchoolPicker from '../common/SchoolPicker';
import { ITSReadingState } from '../../../store/admin/tsReading/reducer';
import { getTSReadings, setTSReadingFilter, setTSReadingList } from '../../../store/admin/tsReading/action';
import { getMetersOptions } from '../meterReading/helper';

const TSReadingFilters = (props: TSReadingFilterProps) => {
    const dispatch = useDispatch()
    const { tsReadingFilter }: ITSReadingState = useSelector<RootState, ITSReadingState>((state) => state.web.tsReadings)
    const [meters, setMeters] = useState<any>([])
    const handleOnChange = async (
        key?: any,
        item?: any
    ) => {
        let filterParams = { ...tsReadingFilter };
        let filters = {}
        switch (key) {
            case "msns":
                let msns = filterParams.msns || [];
                if (item?.selected) {
                    if (!msns?.find((s) => s === item?.key)) {
                        msns.push(item.key);
                    }
                } else {
                    msns = msns?.filter((s) => s !== item?.key) as string[];
                }
                filters = { msns: msns };
                break;
            case "meterType":
                let types = filterParams.type || [];
                if (item?.selected) {
                    if (!types?.find((s) => s === item?.key)) {
                        types.push(item.key);
                    }
                } else {
                    types = types?.filter((s) => s !== item?.key) as string[];
                }
                filters = { type: types };
                break;
            case "selectedSchool":
                filters = { ...filters, schoolSlug: item[0]?.key }
                break;
            case 'selectedSystems':
                filters = { ...filters, system: item[0]?.key, msns: [] }
                break
            default:
                filters = { [key as string]: item };
                break;
        }
        let params = { ...tsReadingFilter, page: 1, ...filters };
        // if (key === 'selectedSchool') {
        //     const { meters } = await getMetersOptions({ systemId: params.system || 'all', schoolSlug: params.schoolSlug || 'all' })
        //     setMeters(meters)

        // } else 
        if (key === 'selectedSystems') {
            dispatch(isLoading(true))
            params = { ...params }
            const { meters } = await getMetersOptions({ systemId: params.system || 'all', schoolSlug: params.schoolSlug || 'all' })
            setMeters(meters)
            dispatch(isLoading(false))
        }
        dispatch(setTSReadingList([], 0, true));
        dispatch(setTSReadingFilter(params));
        if ((params.system && params.msns?.length) || (params.notOnBoard && params.type?.length))
            dispatch(getTSReadings(params));
    }

    const { schoolSlug, start, end, type, notOnBoard, msns, msn = '', date } = tsReadingFilter || {}
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                {/* <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                    <SchoolPicker filters={params} onChange={handleOnChange} />
                </div> */}

                {!notOnBoard && (
                    <>
                        <div className={`ms-Grid-col ms-lg5 margin-top-xsm`}>
                            <SystemPicker filters={{ schoolSlug }} onChange={handleOnChange} />
                        </div>
                        <ComboBox
                            label="Meter"
                            selectedKey={msns! || null}
                            placeholder="Select Meter"
                            multiSelect
                            allowFreeform={true}
                            autoComplete={"on"}
                            options={
                                meters.filter((s: { key: string; }) => s.key !== "all") as IComboBoxOption[]
                            }
                            onChange={(e, item) => handleOnChange('msns', item)}
                            styles={comboBoxStyles}
                            className="ms-Grid-col ms-lg4"
                        /></>
                )}


            </div>
            <div className="ms-Grid-row">
                {!notOnBoard && <>
                    <DatePicker
                        label="Start Date"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={new Date(moment(start).format('YYYY-MM-DD'))!}
                        onSelectDate={(date: any) => handleOnChange('start', moment(date).format('YYYY-MM-DD'))}
                        styles={dropdownStyles}
                        className="ms-Grid-col ms-lg3"
                    />
                    <DatePicker
                        label="End Date"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={new Date(moment(end).format('YYYY-MM-DD'))!}
                        onSelectDate={(date: any) => handleOnChange('end', moment(date).format('YYYY-MM-DD'))}
                        styles={dropdownStyles}
                        className="ms-Grid-col ms-lg3"
                    />
                </>}
                {notOnBoard &&
                    <DatePicker
                        label="Date"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={new Date(moment(date).format('YYYY-MM-DD'))!}
                        onSelectDate={(date: any) => handleOnChange('date', moment(date).format('YYYY-MM-DD'))}
                        styles={dropdownStyles}
                        className="ms-Grid-col ms-lg3"
                    />}
                {notOnBoard &&
                    <ComboBox
                        label="Meter Type"
                        selectedKey={type! || null}
                        placeholder="Select"
                        multiSelect
                        allowFreeform={true}
                        autoComplete={"on"}
                        options={
                            MeterTypes.filter((s) => s.key !== "all") as IComboBoxOption[]
                        }
                        onChange={(e, item) => handleOnChange('meterType', item)}
                        styles={comboBoxStyles}
                        className="ms-Grid-col ms-lg3"
                    />}
                <Checkbox
                    label='Not On Boarded'
                    checked={notOnBoard}
                    className='ms-Grid-col ms-lg3 margin-top-lg'
                    onChange={(e, value) => handleOnChange('notOnBoard', value)}
                />
            </div>
        </div>
    );
}

interface TSReadingFilterProps {

}
export default TSReadingFilters;
