import React, { useState, useEffect } from "react";
import Button from "../learning/Content/formElements/Button";
import Label from "../learning/Content/formElements/Label";
import Textbox from "../learning/Content/formElements/Textbox";
import "./loginStyle.css";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IAdmin } from "../../store/admin/reducer";
import {
  login,
  verifyUserStatus,
  registerAuthEvent,
  setRedirectURL,
} from "../../store/admin/action";
import { IAuthEvent, ILoginCredentials } from "../../store/admin/actionTypes";
import { Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../admin/common/Loader";

const LoginScreen = ({
  web,
  login,
  verifyUserStatus,
  registerAuthEvent,
  setRedirectURL,
}: Props) => {
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get("redirectTo");
  if (web.redirectTo === "" && redirectUrl !== null) {
    setRedirectURL(redirectUrl!);
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailRegularExpression =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (web.isAuthenticated) {
    return <Redirect to="/home" />;
  }
  if (!web.authEvent?.login) {
    window.addEventListener("storage", (e) => {
      if (e.key === "token" && e.newValue !== null) {
        setTimeout(() => {
          verifyUserStatus();
        }, 0);
      }
    });
    registerAuthEvent({ ...web.authEvent, login: true });
  }

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null
  ) => {
    e?.preventDefault();
    if (!email || !emailRegularExpression.test(email.toLowerCase())) {
      toast.error(`Enter valid email address`);
      return;
    }
    if (!password) {
      toast.error(`Enter valid password`);
      return;
    }
    login({
      email,
      password,
    });
  };
  return (
    <section id="main-container">
      {web.isLoading && <Loader />}

      <nav>
        {/* https://pixabay.com/photos/solar-cell-solar-panel-photovoltaic-4045029/ */}
        <img
          className="sfs-logo-header"
          src="/images/sfs-logo.png"
          alt="solar for schools logo"
        />
        <h1 className="page-heading">Solar For Schools Admin</h1>
      </nav>

      <section className="login-container">
        <form>
          <div className="form-group">
            <Label color="var(--solar-blue)" name="Email" fontSize="x-large" />
            <Textbox
              width="25rem"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <Label color="var(--solar-blue)" name="Password" fontSize="x-large" />
            <Textbox
              width="25rem"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <Button onClick={handleSubmit} name="Login" fontSize="x-large" />
          </div>
        </form>
      </section>
    </section>
  );
};

interface DispatchProps {
  login: (credentials: ILoginCredentials) => void;
  verifyUserStatus: () => void;
  registerAuthEvent: (registerAuthEvent: IAuthEvent) => void;
  setRedirectURL: (to: string) => void;
}

interface StateProps {
  web: IAdmin;
}
interface IRootState {
  web: any;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  web: state.web.admin,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    login: async (credentials: ILoginCredentials) => {
      await dispatch(login(credentials));
    },
    verifyUserStatus: async () => {
      await dispatch(verifyUserStatus());
    },
    registerAuthEvent: async (event: IAuthEvent) => {
      await dispatch(registerAuthEvent(event));
    },
    setRedirectURL: async (to: string) => {
      await dispatch(setRedirectURL(to));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);
