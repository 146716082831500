import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { ConvertedObjectType } from "./helper";

export const openOtherConfigDialog = (props: ConvertedObjectType) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement("div");
    document.body.appendChild(mountTarget);
    const callback = (result: ConvertedObjectType) => {
      resolve(result);
      ReactDOM.unmountComponentAtNode(mountTarget);
      mountTarget.remove();
    };
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Edit Configurations"}
          onDismiss={() => callback(undefined)}
        >
          <OtherConfigDialog
            otherConfig={props}
            onCancel={() => callback(undefined)}
            onSave={(otherConfig: ConvertedObjectType) => callback(otherConfig)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    );
  });
};
const OtherConfigDialog = (props: IOtherConfigDialogProps) => {
  const { onSave, onCancel, otherConfig } = props;

  const otherConfigsObj: any = cloneDeep(otherConfig);

  const newOtherConfigs = Object.keys(otherConfigsObj[0]).reduce(
    (acc: any, key) => {
      if (typeof otherConfigsObj[0][key] === "number") acc[key] = 0;
      else acc[key] = "";
      return acc;
    },
    {}
  );

  const [textFields, setTextFields] = useState(otherConfigsObj);
  const tableHeader = Object.keys(otherConfigsObj[0]);

  const handleChangeInput = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event?.target as HTMLInputElement;
    const tempTextFields: Record<string, any> = [...textFields];
    if (type === "text") tempTextFields[index][name] = value.trim();
    else if (type === "number") {
      tempTextFields[index][name] = Number(value);
    }
    setTextFields(tempTextFields);
  };

  const handleRemoveClick = (index: number) => {
    const tempTextFields = [...textFields];
    tempTextFields.splice(index, 1);
    setTextFields(tempTextFields);
  };

  const handleAddClick = () => {
    setTextFields([...textFields, newOtherConfigs]);
  };

  const handleOnSave = async () => {
    //Filter the empty objects value
    const resultantTextFields = textFields.filter(
      (obj: Record<string, any>) => {
        return !Object.values(obj).every(
          (value) => value === "" || value === null
        );
      }
    );

    const isConfirmed = await ConfirmDialog({
      dialogContentProps: {
        title: "Save Configuration",
        closeButtonAriaLabel: "Cancel",
        subText: "Are you want to save this configuration?",
      },
      confirmBtnText: "Save",
    });
    if (isConfirmed) onSave(resultantTextFields);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", margin: "1rem" }}>
        Configuration Lists
      </h2>

      <div className=" otherConfigTable">
        <table className="otherConfigTable">
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              {tableHeader.map((value: string) => (
                <td>{value.toUpperCase().replace(/[0-9]/g, "")}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {textFields.map((item: any, index: number) => {
              return (
                <>
                  {" "}
                  <tr key={index}>
                    {tableHeader.map((el: string) => {
                      return (
                        <td>
                          <TextField
                            type={
                             item[el] ?( typeof item[el] === "string" ? "text" : "number") : "text"
                            }
                            onChange={(event) =>
                              handleChangeInput(event, index)
                            }
                            placeholder={`Enter the ${el
                              .toLowerCase()
                              .replace(/[0-9]/g, "")}`}
                            value={item[el]}
                            name={el}
                          />
                        </td>
                      );
                    })}
                    <td>
                      <ActionButton
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => handleRemoveClick(index)}
                        className="deleteBtn otherConfigDeleteBtn"
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="otherConfigsAdd">
        <DefaultButton text="Add New Column" onClick={handleAddClick} />
      </div>

      <div className="otherConfigsBtn">
        <PrimaryButton
          text="Save"
          className="otherConfigsBtn-save"
          onClick={handleOnSave}
        />
        <DefaultButton
          onClick={onCancel}
          className="otherConfigsBtn-cancel"
          text="Cancel"
        />
      </div>
    </div>
  );
};

export default OtherConfigDialog;

interface IOtherConfigDialogProps {
  otherConfig: ConvertedObjectType;
  onCancel: () => void;
  onSave: (otherConfig: ConvertedObjectType) => void;
}
