import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Loader from '../common/Loader';
import InvoiceList from './InvoiceList';
import { exportBillingInvoices, fetchInvoices, setInvoiceFilter } from '../../../store/admin/invoice/action';
import InvoiceFilters from './InvoiceFilter';
import { faFileExcel, faVoicemail, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from '../../../store';
import { IInvoice } from '../../../store/admin/invoice/reducer';
import { OpenAddInvoiceModal } from './AddInvoiceDialog';
import { toast } from 'react-toastify';
import { isLoading } from '../../../store/admin/action';
import { getInvoices, getInvoiceEmailPreview, saveInvoiceEmailAsDraft } from '../../../store/client/invoice';
import { OpenEmailPreviewModal } from '../common/EmailPreviewDialog';
import { ActionButton, Dropdown, IDropdownOption } from '@fluentui/react';
import { PageSize } from '../common/common';

const Index = () => {

  const dispatch = useDispatch()
  const { invoices, totalCount, invoiceFilter }: IInvoice = useSelector<RootState, IInvoice>((state: RootState) => state.web.invoice)
  const [selectedItems, setSelectedItems] = useState<Array<any> | []>([])

  useEffect(() => {
    dispatch(fetchInvoices())
  }, []);

  const openEmailPreview = async (param: any, invoiceData?: IInvoice[]) => {
    try {
      dispatch(isLoading(true))
      const { data = [] } = invoiceData?.length ? invoiceData : await getInvoices({ ...param, isGenerated: true, checkAuditLog: true, pageSize: totalCount, page: 1 })
      if (!data.length) {
        dispatch(isLoading(false))
        return
      }
      const { html }: any = await getInvoiceEmailPreview({ invoiceId: data[0]._id })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [...data],
          title: 'Invoice Email Preview',
          btnTitle: 'Save Draft',
          html,
          sendAction: saveInvoiceEmailAsDraft
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }
  const handleOnExport = () => {
    dispatch(exportBillingInvoices())
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...invoiceFilter, page: 1, pageSize: size }
    dispatch(setInvoiceFilter(filters))
    dispatch(fetchInvoices())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Billing Invoices</h1>
        <InvoiceFilters />
      </header>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={invoiceFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        {
          selectedItems?.length > 0 && (
            <ActionButton
              allowDisabledFocus
              style={{ textAlign: 'right' }}
              title='Save as Draft'
              onClick={async () => {
                await openEmailPreview(invoiceFilter)
              }}
            ><FontAwesomeIcon icon={faVoicemail} size='1x' color='#106ebe' />  &nbsp;Send Invoice (selected)
            </ActionButton>
          )
        }
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnExport()}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
        {invoiceFilter.cycle !== 'all' && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            title='Save as Draft'
            onClick={async () => {
              await openEmailPreview(invoiceFilter)
            }}
          ><FontAwesomeIcon icon={faVoicemail} size='1x' color='#106ebe' />  &nbsp;Send Invoice
          </ActionButton>
        )}
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () =>
            await OpenAddInvoiceModal()
          }
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Generate Invoice
        </ActionButton>
      </div>
      {invoices.length && <InvoiceList setSelectedInvoices={(data: IInvoice[]) => setSelectedItems(data)} />}
    </div>
  )
}
export default Index;

