import { IEstablishment, IEstablishmentDocument } from "@solarforschools/sfs-core/dist/school/types"
import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"

let schoolFetchRequest: AbortController
let schoolMetaFetchRequest: AbortController


export const getSchoolApi = (slug: any): Promise<any> => {
  return axios.get(`${host}/school/${slug}`).then(res => res.data)
}

export const getSchoolsApi = (query: any): Promise<any> => {
  if (schoolFetchRequest) {
    schoolFetchRequest.abort(); // Tell the browser to abort request
  }
  schoolFetchRequest = new AbortController();
  const signal = schoolFetchRequest.signal
  return axios.get(`${host}/school?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: schoolFetchRequest.signal }).then(res => res.data)
}

export const getSchoolsMetaApi = (type: string, searchText: string): Promise<any> => {
  if (schoolMetaFetchRequest) {
    schoolMetaFetchRequest.abort(); // Tell the browser to abort request
  }
  schoolMetaFetchRequest = new AbortController();
  const signal = schoolMetaFetchRequest.signal
  return axios.get(`${host}/school/meta/${type}?search=${searchText}`, { signal }).then(res => res.data)
}

export const validateSchoolAPi = (school: IEstablishment): Promise<any> => {
  return axios
    .put(`${host}/school/validate/school`, school)
    .then((res) => res.data);
};

export const assignSchoolApi = (params: any): Promise<any> => {
  return axios
    .put(`${host}/school/assign`, params)
    .then((res) => res.data);
};

export const getSchoolCountriesApi = (query: ParsedUrlQueryInput): Promise<any> => {
  return axios.get(
    `${host}/school/countries-filter/distinct?${generateQueryString(
      query as ParsedUrlQueryInput
    )}`
  ).then(res => res.data);
}
export const getSchoolFiltersApi = (query: ParsedUrlQueryInput): Promise<any> => {
  return axios.get(
    `${host}/school/school-filter/services-status?${generateQueryString(
      query as ParsedUrlQueryInput
    )}`
  ).then(res => res.data);
}

export const exportSchoolsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/school/download?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const deleteSchoolApi = (slug: string): Promise<any> => axios.delete(`${host}/school/${slug}`).then(res => res.data)

export const createScchoolAPi = (data: IEstablishment): Promise<any> => {
  return axios
    .post(`${host}/school`, data)
    .then((res) => res.data);
};

export const updateSchoolApi = (data: IEstablishment): Promise<any> => {
  return axios
    .put(`${host}/school/${data.slug}`, data)
    .then((res) => res.data);
};

export const LockDesignApi = (designId: string, isLocked: boolean): Promise<any> => {
  return axios
    .put(`${host}/school/lockdesign/${designId}`, { isLocked })
    .then((res) => res.data);
};

export const multipleLockDesignApi = (designIds: any[], isLocked: boolean): Promise<any> => {
  return axios
    .put(`${host}/school/toggelelockdesign/many`, { designIds, isLocked })
    .then((res) => res.data);
};
