import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../../utils/util"
import { host } from "../../config"
import { IGSSReportFilter } from "../../../components/admin/reporting/gssReporting/types"
import { IDatafeedFilter } from "../../../components/admin/reporting/datafeed/types"

let GetDFAPiAbortController: AbortController

export const getDatafeedDataApi = (params: IDatafeedFilter): Promise<any> => {
  if (GetDFAPiAbortController) GetDFAPiAbortController.abort()
  GetDFAPiAbortController = new AbortController()
  return axios.post(`${host}/reports/datafeed`, { ...params }, { signal: GetDFAPiAbortController.signal }).then(res => res.data)
}

export const getDatafeedDataExportApi = (params: IDatafeedFilter): Promise<any> => {
  return axios.post(`${host}/reports/datafeed/export`, { ...params }, { responseType: 'arraybuffer' }).then(res => res.data)
}
