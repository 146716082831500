
import { IOpportunity as IOpportunityDocument } from "@solarforschools/sfs-core/dist/opportunity/type";
import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { generateQueryString } from "../../../utils/util";
import { host } from "../../config";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IOpportunityFilter } from "../../../components/admin/opportunity/types";
import { IStage } from "@solarforschools/sfs-core/dist/school/types";
import { createOpportunityAPi, deleteOpportunityApi, exportOpportunityApi, getOpportunitiesApi, updateOpportunityApi } from "../../client/opportunity";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { updateSchool } from "../school/action";
import { updateProject } from "../../client";
import { setUpdatedProject } from "../projects/action";


// Action Creators

export const setOpportunityFilter = (opportunityFilter: IOpportunityFilter) => {
  return { type: "SET_OPPORTUNITY_FILTER", opportunityFilter };
};


export const setOpportunityList = (opportunities: IOpportunityDocument[], totalCount: number, reset: boolean) => {
  return { type: "SET_OPPORTUNITY_LIST", opportunities, totalCount, reset };
};

export const updateOpportunityList = (opportunity: IOpportunityDocument) => {
  return { type: "UPDATE_OPPORTUNITY", opportunity };
};

export const delOpportunity = (opportunity: IOpportunityDocument) => {
  return { type: "DELETE_OPPORTUNITY", opportunity };
};

export const getOpportunities =
  (params?: IOpportunityFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IOpportunityFilter =
            getState().web.opportunity.opportunityFilter;
          reqBody = { ...filters };
        }
        const { opportunities, totalCount } = await getOpportunitiesApi(reqBody);
        dispatch(setOpportunityList(opportunities, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const createUpdateOpportunity = (
  params: IOpportunityDocument,
  stage: IStage,
  isShowLoader: boolean = true
) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      const payload = {
        opportunity: { ...params },
        stage: { ...stage }
      }
      const { opportunity, msg } = params._id
        ? await updateOpportunityApi(payload)
        : await createOpportunityAPi(payload);
      dispatch(updateOpportunityList(opportunity));
      if (opportunity.type === 'School') {
        dispatch(updateSchool({ ...opportunity.school, activeStage: stage }))
      } else {
        dispatch(setUpdatedProject({ ...opportunity.project, activeStage: stage }))
      }
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const deleteOpportunity =
  (params: IOpportunityDocument) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteOpportunityApi(params);
        dispatch(delOpportunity(params));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const fetchOpportunityFilter = async (query?: any) => {
  try {
    const res = await axios.get(`${host}/opportunity/table/filter?${generateQueryString(query)}`);
    const regions = [{ key: 'all', text: 'All' }, ...res.data.regions.map((r: any) => { return { key: r, text: r } })]
    const users = [{ key: 'all', text: 'All' }, ...res.data.users.map((r: any) => { return { key: r._id.toString(), text: `${r.userName}(${r.userEmail})` } })]
    return { regions, users }
  } catch (error) {
    console.log(error);
    return { regions: [], users: [] }
  }
}

export const exportOpportunities = (params?: IOpportunityFilter, reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IOpportunityFilter =
          getState().web.opportunity.opportunityFilter;
        reqBody = { ...filters };
      }
      const res = await exportOpportunityApi(reqBody)
      downloadFileFromStream({ data: res.data, filename: "Opportunities", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something happend wrong')
    }
  };
