import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminDashboardDataSuccess, fetchDashboardData } from "../../store/admin/adminDashboard/actions";
import Log from "./Log";
import { RootState } from "../../store";
import { IAdminDashboardDataState } from "../../store/admin/adminDashboard/reducers";
import Shimmer from "./adminDashboard/layouts/shimmer/Shimmer";
import { ActionButton } from "office-ui-fabric-react";
import { SocketContext } from "../../context/socket";
import { toast } from "react-toastify";
import moment from "moment";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { generateTilesMetaData } from "./adminDashboard/utils/dashboardHelper";

function Dashboard() {
  const dispatch = useDispatch()
  const [waitForRender, setWaitForRender] = useState<boolean>(true);
  const { coreSocket } = useContext(SocketContext);
  const [toggle, setToggle] = useState<boolean>(false); // Toggle Sync button
  const spinnerStyles = {
    display: "flex",
    alignItems: "center",
  }


  useEffect(() => {
    dispatch(fetchDashboardData())
    setWaitForRender(false)
  }, [])

  useEffect(() => {
    coreSocket.on("adminDashboard script result", (res) => {

      if (res.result === "AdminDashboard Scripts Successfully Completed" && Array.isArray(res.resData)) {
        setToggle(false)
        toast.success("Synchronization completed", {
          toastId: 'success'
        });
        dispatch(fetchAdminDashboardDataSuccess(res.resData))
      }
      else toast.error("Something went wrong....");
    })
  }, [])

  const handleRefresh = () => {
    setToggle(true)
    toast.success("Synchronization started");
    coreSocket.emit("run adminDashboard script", "adminDashboard")
  }


  const SpinnerButton = () => <div style={spinnerStyles}> <Spinner size={SpinnerSize.medium} /><span style={{ marginLeft: 7, color: "#fff", fontSize: "0.85rem" }}> Syncing...</span> </div>
  const SyncButton = () => <div>
    <ActionButton
      onClick={handleRefresh}
      iconProps={{ iconName: "Refresh" }}
      style={{ color: "#fff" }}
      allowDisabledFocus
    >
      Sync
    </ActionButton>
  </div>


  return (
    <div className="dashboard">
      <div className="dashboard-header">
        {toggle ? <SpinnerButton /> : <SyncButton />}
        <div>
          <Log />
        </div>
      </div>
        <Layout waitForRender={waitForRender} />
    </div>
  );
}

const Layout = (props: IProps) => {
  const [syncDateTime, setSyncDateTime] = useState<Date>()
  const data = useSelector<RootState, IAdminDashboardDataState>(
    (state) => state.web.adminDashboardData
  );

  // To get the date based on client's browser
  const getLastSyncedDateTime = () => {
    let dateFormat = "DD-MMM-YYYY hh:mm:ss A"
    // .local(true) will convert date to local date time
    let localDateTime = moment(syncDateTime).local(true).format(dateFormat);
    return localDateTime
  }

  const { isLoading, error } = data;

  useEffect(() => {
    setSyncDateTime(data.adminDashboardData[0]?.syncDateTime)
  }, [data.adminDashboardData[0]?.syncDateTime])

  if (isLoading || props?.waitForRender === true) return <Shimmer />

  if (error) return <div style={{ textAlign: "center" }}> {error}</div>


  const tiles = generateTilesMetaData(data)

  return (
    <div>
      <div className="dashboard-content">
        <div className="flex-row-center">
          <div className="dashboard-layout">

            {
              tiles.map((tile, index) => {
                let { component: Component, props: { key, value } } = tile;

                return <React.Fragment key={index}>
                  {React.createElement(Component, { [key]: value })}
                </React.Fragment>
              })
            }

          </div>
        </div>

        <div className="dashboard-syncTime">
          Last Synced: {getLastSyncedDateTime()}
        </div>

      </div>
    </div>
  )
}


export default Dashboard;
interface IProps {
  waitForRender: boolean
}
